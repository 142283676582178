import React, { useEffect, useState } from "react";
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { zodResolver } from "@hookform/resolvers/zod";

import { 
  Button, TextField,
} from "@mui/material";

import LabelInputWrapper from "../../components/form/LabelnputWrapper";
import SelectInput from "../../components/custom-input/SelectInput";

import { BarInput, BarSchema } from "../../lib/validations/bar.schema";
import { placeBar } from "../../redux/actions/bar.action";
import { ReduxResponse } from "../../types/store";
import { getCellphonecarrierList } from "../../redux/actions/cellphonecarrier.action";
import { getCourtList } from "../../redux/actions/court.action";
import { stateInfo as stateList } from "../../config/static-data";

export default function CreateBarPage() {
  const navigate = useNavigate();
  const location = useLocation();  
  const queryParams = new URLSearchParams(location.search);  
  const isFromOrder = queryParams.get("isFromOrder");  
  const dispatch: Dispatch = useDispatch();
  const { user } = useSelector(({ cognitouserReducer }) => cognitouserReducer);

  // Destructure form hooks for the input
  const methods = useForm<BarInput>({ resolver: zodResolver(BarSchema) });
  const { register, handleSubmit, reset, formState } = methods;
  const { errors } = formState;

  const [fieldValueBarState, setFieldValueBarState] = useState("");

  
  useEffect(() => {
    dispatch(getCourtList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCellphonecarrierList());
  }, [dispatch]);


  const clickCancel = () => {
    if (isFromOrder) {
      navigate("/order/save");
    } else {
      navigate("/user/bars");
    }
  };

  // Open confirmation dialog when submit
  const onSubmit = (data: BarInput) => {
    if (user) {
      if (user.state && user.email) {
        dispatch(placeBar(data))
          .then((response: ReduxResponse) => {
            if (response.success) {
              reset();
              toast.success(response.message);
              reset();
              if (isFromOrder) {
                navigate("/order/save");
              } else {
                navigate("/user/bars");
              }
            } else {
              toast.error(response.message);
            }
          })
          .catch((error: Error) => {
            toast.error(error.message);
          });
      } else {
        toast.error("User is not for bar-party. Please complete your profile.");
      }
    } else {
      toast.error("Please login to the platform.");
    }
  };

  return (
    <main className="flex justify-center w-full">
      <form className="container flex flex-col w-full pl-8 pr-24 py-16 fade-up-anim anim-500" onSubmit={handleSubmit(onSubmit)}>
        <h1 className="text-3xl ml-4 mb-8">CREATE A BAR</h1>
        <LabelInputWrapper label="Bar State:" className="w-full md:w-3/5">
          <SelectInput
            variant="standard"
            className="w-full"
            handler={register("barState")}
            error={errors.barState ? true : false}
            helperText={errors.barState?.message}
            options={{
              data: stateList,
              labelKey: "name",
              valueKey: "abbreviation",
              value: fieldValueBarState
            }}
            onChange={(e) => {setFieldValueBarState(e.target.value);}}
          />
        </LabelInputWrapper>
        <LabelInputWrapper label="Bar Number:" className="w-full md:w-3/5">
          <TextField
            variant="standard"
            className="w-full"
            {...register("barNumber")}
            error={errors.barNumber ? true : false}
            helperText={errors.barNumber?.message}
          />
        </LabelInputWrapper>
        <div className="flex">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="w-48 h-12 mt-12 ml-4 bg-primary text-lg tracking-widest"
          >
            {"submit"}
          </Button>

          <Button
            variant="contained"
            color="error"
            className="w-48 h-12 mt-12 ml-4 bg-red-500 text-lg tracking-widest"
            onClick={() => clickCancel()}
          >
            {"cancel"}
          </Button>
        </div>
      </form>
    </main>
  );
}
