import * as Actions from "../constants";
import { DispatchAction } from "../../types/store";

const initialState = {
  isLoading: false,
  error: null,
  total: 0,
  list: []
};

const courtReducer = (state = initialState, action: DispatchAction) => {
  switch (action.type) {
  case Actions.GET_COURT_LIST_REQUEST: {
    return {
      ...state,
      isLoading: true
    };
  }
  case Actions.GET_COURT_LIST_SUCCESS: {
    return {
      ...state,
      total: action.payload.data.length,
      list: [...action.payload.data],
      isLoading: false
    };
  }
  case Actions.GET_COURT_LIST_FAILURE: {
    return {
      ...state,
      isLoading: false,
      list: [],
      error: action.error
    };
  }

  case Actions.ADD_COURT_REQUEST: {
    return {
      ...state,
      isLoading: true,
    };
  }
  case Actions.ADD_COURT_SUCCESS: {
    let newOne = true;
    let newArray = state.list.map((row:any) => {
      if(row.ID === action.payload.data["ID"]) {
        newOne = false;
        return {...action.payload.data};
      } else
        return row;
    });
    if(newOne) {
      newArray = [...newArray, action.payload.data];
    }

    return {
      ...state,
      isLoading: false,
      list: [...newArray]
    };
  }
  case Actions.ADD_COURT_FAILURE: {
    return {
      ...state,
      isLoading: false
    };
  }
  default: {
    if(state)
      return state;
    return initialState;
  }
  }
};

export default courtReducer;