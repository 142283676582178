import * as React from "react";
import {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { 
  Button, Dialog, DialogContent, DialogTitle,
  Checkbox,
  Stack
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ButtonLoader from "../../components/shared/ButtonLoader";
import LabelInputWrapper from "../../components/form/LabelnputWrapper";

import { orderLabelByType } from "../../config/static-data";
import { getFieldValueFromList } from "../../utils/functions";


export default function ConfirmCreatingOrderPage(props:any) {
  const [enableSubmit, setEnableSubmit] = useState(false);
  const {submitData} = props;

  useEffect(()=>{
    setEnableSubmit(false);
  }, [props.open]);

  const { list:listOfCellphoneCarriers } = useSelector(({ cellphoneCarrierReducer }) => cellphoneCarrierReducer  );
  const { list:listOfCourts } = useSelector(({ courtReducer }) => courtReducer);
  // const { list:listOfBars } = useSelector(({ barsReducer }) => barsReducer);
  
  if(submitData) {
    // console.log(listOfBars);
    // console.log(submitData);

    return(
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle className="text-center">
          <InfoIcon
            sx={{
              fontSize: 32,
              color: "orange"
            }}
          />
        </DialogTitle>

        <DialogContent className="flex flex-col">
          <p className="my-4 text-center text-xl">Do you agree with this order?</p>
          <LabelInputWrapper label="Document Type:">
            {orderLabelByType(props.submitData["type"])}
          </LabelInputWrapper>
          <LabelInputWrapper label="Court of Docket:">
            {getFieldValueFromList(listOfCourts, "code", props.submitData["court"])}
          </LabelInputWrapper>
          <LabelInputWrapper label="Cause Number:">
            {props.submitData["causeNumber"]}
          </LabelInputWrapper>
          <LabelInputWrapper label="Plaintiff List:">
            {props.submitData["plaintiffs"]}
          </LabelInputWrapper>
          <LabelInputWrapper label="Defendant List:">
            {props.submitData["defendants"]}
          </LabelInputWrapper>
          <LabelInputWrapper label="Notification Email List:">
            {props.submitData["notificationEmailItems"].map((item:any)=>item.email).join("\n")}
          </LabelInputWrapper>
          <LabelInputWrapper label="Cell Phone Number:">
            {props.submitData["orderphoneNumber"]}
          </LabelInputWrapper>
          <LabelInputWrapper label="Customer Information:">
            {props.submitData["ordercustomerinfo"]}
          </LabelInputWrapper>
          <LabelInputWrapper label="Order Start Date:">
            {props.submitData["orderstartdate"]}
          </LabelInputWrapper>
          <LabelInputWrapper label="Order Stop Date:">
            {props.submitData["orderstopdate"]}
          </LabelInputWrapper>
          <LabelInputWrapper label="Carrier Code:">
            {getFieldValueFromList(listOfCellphoneCarriers, "companyname", props.submitData["phoneCode"])}
          </LabelInputWrapper>
          <LabelInputWrapper label="Notify?:">
            {props.submitData["notify"] ? "YES" : "NO"}
          </LabelInputWrapper>  
          <LabelInputWrapper label="Agree to place this order:">
            <Checkbox
              value={enableSubmit}
              checked={enableSubmit }
              onChange={()=>setEnableSubmit(!enableSubmit)}
              inputProps={{ "aria-label": "Checkbox A" }}
            />
          </LabelInputWrapper>  
          <Stack sx={{ flexDirection: "row", alignSelf: "end" }} gap={2}>
            <Button
              variant="contained"
              color="primary"
              className="bg-primary"
              onClick={props.onApprove}
              disabled={!enableSubmit}
            >
              {props.isLoading ? <ButtonLoader /> : "Approve"}
            </Button>

            <Button
              variant="contained"
              color="error"
              className="bg-red-500"
              onClick={props.onCancel}
            >
              {"Cancel"}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    );
  } else
    return(
      <>
      </>
    );
}