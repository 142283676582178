import React, { useState, useEffect } from "react";
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { zodResolver } from "@hookform/resolvers/zod";
import axios, { AxiosError } from "axios";

import { 
  Button, FormControl, FormControlLabel, Radio, RadioGroup, TextField, FormHelperText,
  Grid
} from "@mui/material";


import { orderType } from "../../config/static-data";
import SelectInput from "../../components/custom-input/SelectInput";

import { OrderInput, OrderSchema } from "../../lib/validations/order.schema";
import { getOrderDraft, placeOrder, placeOrderDraft, deleteOrderDraft } from "../../redux/actions/order.action";
import { ReduxResponse } from "../../types/store";
import { /*clearFormat,*/ getStateCode } from "../../utils/functions";
import { getCellphonecarrierList } from "../../redux/actions/cellphonecarrier.action";
import { getCourtList } from "../../redux/actions/court.action";
import { getBarList } from "../../redux/actions/bar.action";

import ConfirmDlg from "./ConfirmCreatingOrderPage";
import OrderAccountLinkTable from "../../components/order/OrderAccountLinkTable";
import NotificationEmailItemAddDialog from "../../components/order/NotificationEmailItemAddDialog";
import FormAutoComplete from "../../components/form/FormAutoComplete";
import { useSelectOptionsMemo } from "../../hooks/useSelectOptionsMemo";
import { FormSelectOption } from "../../types/common";
import AddCourtModal from "../../components/modals/AddCourtModal";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import CancelConfirmationModal from "../../components/modals/CancelConfirmationModal";

interface NotificationEmailItem {
  email: string;
  partyRepresented: string;
  orderingParty: string;
  barID: string;
  // Add any other properties if needed
}

export default function UpdateOrderPage() {
  const navigate = useNavigate();
  const dispatch: Dispatch = useDispatch();
  const location = useLocation();
  const updateRecord = location.state?.updateRecord;

  const { user, paymentlist } = useSelector(({ cognitouserReducer }) => cognitouserReducer);
  const { isLoading } = useSelector(({ orderReducer }) => orderReducer);
  const [ isLoadingNotificationTable, setIsLoadingNotificationTable ] = useState(false);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isCourtModalOpen, setIsCourtModalOpen] = useState(false);
  const [isCancelConfirmationModalOpen, setIsCancelConfirmationModalOpen] = useState(false);
  const [notify, setNotify] = useState<boolean>(true);
  const [submitData, setSubmitData] = useState<any>(null);

  // Destructure form hooks for the input
  const methods = useForm<OrderInput>({ resolver: zodResolver(OrderSchema) });
  const { register, handleSubmit, reset, formState, setValue } = methods;
  const { errors } = formState;

  // Handle confirm dialog
  const handleConfirmDialogOpen = () => setIsConfirmDialogOpen(true);
  const handleConfirmDialogClose = () => setIsConfirmDialogOpen(false);
  
  const { list:listOfCourts } = useSelector(({ courtReducer }) => courtReducer);
  const { list:listOfCellphoneCarriers } = useSelector(({ cellphoneCarrierReducer }) => cellphoneCarrierReducer  );
  const { list:listOfBars } = useSelector(({ barsReducer }) => barsReducer);
  const { draft } = useSelector(({ orderReducer }) => orderReducer);
  const [ listOfBarsForCurrentCourt, setListOfBarsForCurrentCourt ] = useState<Array<any>>([]);

  const [notificationEmailItems, setNotificationEmailItems] = useState<Array<any>>([]);

  const [notificationemailItemAddDlgOpen, setNotificationemailItemAddDlgOpen] = useState(false);
  const handleNotificationemailItemAddDlgClose = () => setNotificationemailItemAddDlgOpen(false);
  const [notificationEmailItemUpdateContent, setNotificationEmailItemUpdateContent] = useState<NotificationEmailItem>({ email: "", partyRepresented: "", orderingParty: "", barID: "" });

  // const [fieldValueOrderNumber, setFieldValueOrderNumber] = useState("");
  const [fieldValueType, setFieldValueType] = useState("");
  const [fieldValueCauseNumber, setFieldValueCauseNumber] = useState("");
  const [fieldValuePlaintiffList, setFieldValuePlaintiffList] = useState("");
  const [fieldValueDefendantList, setFieldValueDefendantList] = useState("");
  const [fieldValuePhoneNumber, setFieldValuePhoneNumber] = useState("");
  const [fieldValueCustomerInformation, setFieldValueCustomerInformation] = useState("");
  const [fieldValueOrderStart, setFieldValueOrderStart] = useState<Dayjs | null>(null);
  const [fieldValueOrderEnd, setFieldValueOrderEnd] = useState<Dayjs | null>(null);
  const [fieldValueCourt, setFieldValueCourt] = useState("");
  const [fieldValuePhoneCode, setFieldValuePhoneCode] = useState("");
  const [fieldValueBarNumber, setFieldValueBarNumber] = useState("");

  //select options for court auto complete component
  const courtSelectOptions = useSelectOptionsMemo(
    listOfCourts,
    "code",
    "ID",
    "label"
  );

  useEffect(() => {
    const SelectedCard = paymentlist.find((item:any) => item.verified === "yes" && item.paymentId === user.primarypayment);
    if(paymentlist.length > 0 && !SelectedCard) {
      toast.warn("To create order, you should have verified payment method!");
      navigate("/user/paymentcard");
    }
  }, [paymentlist]);
  
  useEffect(() => {
    dispatch(getCourtList());
    dispatch(getCellphonecarrierList());
    dispatch(getBarList());
    if(updateRecord?.ID) {
      // console.log("update");
    } else {
      dispatch(getOrderDraft());
    }
  }, [dispatch]);

  useEffect(() => {
    setNotify(updateRecord ? updateRecord?.notify : draft ? draft[0].notify : true);
    
    if(draft) {
      // setFieldValueOrderNumber(updateRecord ? updateRecord?.orderNumber : draft[0]?.orderNumber);
      setFieldValueType(updateRecord ? updateRecord?.type : draft[0]?.type);
      setFieldValueCauseNumber(updateRecord ? updateRecord?.causeNumber : draft[0]?.causeNumber);
      setFieldValuePlaintiffList(updateRecord ? updateRecord?.plaintiffs : draft[0]?.plaintiffs);
      setFieldValueDefendantList(updateRecord ? updateRecord?.defendants : draft[0]?.defendants);
      setFieldValuePhoneNumber(updateRecord ? updateRecord?.orderphoneNumber : draft[0]?.orderphoneNumber);
      setFieldValueCustomerInformation(updateRecord ? updateRecord?.ordercustomerinfo : draft[0]?.ordercustomerinfo);
      setFieldValueOrderStart(updateRecord ? dayjs(updateRecord?.orderstartdate) : dayjs(draft[0]?.orderstartdate));
      setFieldValueOrderEnd(updateRecord ? dayjs(updateRecord?.orderstopdate) : dayjs(draft[0]?.orderstopdate));
      setFieldValueCourt(updateRecord ? updateRecord?.court : draft[0]?.court);
      setFieldValueBarNumber(updateRecord ? updateRecord?.barNumber : draft[0]?.barNumber);
      setFieldValuePhoneCode(updateRecord ? updateRecord?.phoneCode : draft[0]?.phoneCode);
      setNotify(updateRecord ? updateRecord?.notify : draft[0] ? draft[0].notify : true);
    }
    
    if( !updateRecord ) {
      // console.log("Draft0", draft ? draft[0].notificationEmailItems : []);
      setNotificationEmailItems(draft ? draft[0].notificationEmailItems : []);
    } else {
      setIsLoadingNotificationTable(true);

      axios.post(`${process.env.REACT_APP_SERVER_API}/order/notificationtabledetail`, {notificationEmailItems : updateRecord?.notificationEmailItems})
        .then(response => {
          if(response.data.success) {
            setNotificationEmailItems(response.data.data);
          }
          setIsLoadingNotificationTable(false);
        })
        .catch((error: AxiosError) => {
          console.log(error);
          setNotificationEmailItems([]);
          setIsLoadingNotificationTable(false);
          navigate("/");
        });
    }

  }, [updateRecord, draft]);

  useEffect(() => {
    setValue("type", fieldValueType);
    setValue("causeNumber", fieldValueCauseNumber);
    setValue("defendants", fieldValueDefendantList);
    setValue("orderphoneNumber", fieldValuePhoneNumber);
    setValue("ordercustomerinfo", fieldValueCustomerInformation);
    setValue("orderstartdate", fieldValueOrderStart?.toString() || "");
    setValue("orderstopdate", fieldValueOrderEnd?.toString() || "");
    setValue("plaintiffs", fieldValuePlaintiffList);
    setValue("notificationEmailItems", notificationEmailItems);
    setValue("court", fieldValueCourt);
    setValue("barNumber", fieldValueBarNumber);
    setValue("notify", notify);
    setValue("phoneCode", fieldValuePhoneCode);
    saveDraft();
  }, [
    setValue, 
    fieldValueType, 
    fieldValueCauseNumber, 
    fieldValueDefendantList, 
    fieldValuePhoneNumber, 
    fieldValueCustomerInformation, 
    fieldValueOrderStart, 
    fieldValueOrderEnd, 
    fieldValuePlaintiffList, 
    notificationEmailItems, 
    notify, 
    fieldValueCourt, 
    fieldValueBarNumber, 
    fieldValuePhoneCode
  ]);

  useEffect(() => {
    const currentSelectedCourt = listOfCourts.find((court:any) => court.ID === fieldValueCourt);
    const filteredListOfBars = listOfBars.filter((bar:any) => bar?.barState?.toLowerCase() === currentSelectedCourt?.code?.toLowerCase());
    setListOfBarsForCurrentCourt(filteredListOfBars);
  }, [fieldValueCourt]);

  useEffect(() => {
    if(listOfBarsForCurrentCourt.find((bar:any) => bar.ID === updateRecord?.barNumber))
      setFieldValueBarNumber(updateRecord?.barNumber);
    else
      setFieldValueBarNumber(listOfBarsForCurrentCourt[0]?.ID);
  }, [listOfBarsForCurrentCourt]);

  const clickApprove = () => {
    toast.success("You should start the waiting period for this order to proceed after making the payment.");

    // console.log(placeOrder, reset);
    if(updateRecord) {
      dispatch(placeOrder({
        ...submitData
      }))
        .then((response: ReduxResponse) => {
          emptyDraft();
          handleConfirmDialogClose();
  
          if (response.success) {
            toast.success(response.message);
            reset();
            navigate("/");
          } else {
            toast.error(response.message);
          }
        })
        .catch((error: Error) => {
          handleConfirmDialogClose();
          toast.error(error.message);
        });
    } else {
      navigate("/order/payment", {state: {newOrderData: submitData}});
    }
  };
  
  const saveDraft = () => {
    if(updateRecord?.ID) {
      // console.log("update");
    } else {
      // console.log("NotificationEmailItems", notificationEmailItems);
      const draftData = {
        type: fieldValueType,
        causeNumber: fieldValueCauseNumber,
        defendants: fieldValueDefendantList,
        orderphoneNumber: fieldValuePhoneNumber,
        ordercustomerinfo: fieldValueCustomerInformation,
        orderstartdate: fieldValueOrderStart?.toString() || "",
        orderstopdate: fieldValueOrderEnd?.toString() || "",
        plaintiffs: fieldValuePlaintiffList,
        notificationEmailItems: notificationEmailItems,
        court: fieldValueCourt,
        barNumber: fieldValueBarNumber,
        notify: notify,
        phoneCode: fieldValuePhoneCode,

      };
  
      dispatch(placeOrderDraft({
        ...draftData
      }))
        .then((response: ReduxResponse) => {
          console.log(response);
        })
        .catch((error: Error) => {
          console.error(error);
        });
    }
  };

  const emptyDraft = () => {
    if(updateRecord?.ID) {
      // console.log("empty");
    } else {
    
      dispatch(deleteOrderDraft({
      }))
        .then((response: ReduxResponse) => {
          console.log(response);
        })
        .catch((error: Error) => {
          console.log(error);
        });
    }
  };
  
  const clickCancel = () => {
    emptyDraft();
    navigate("/");
    //handleConfirmDialogClose();
  };

  const openAddNotificationEmailItemDlg = () => {
    setNotificationEmailItemUpdateContent({ email: "", partyRepresented: "", orderingParty: "", barID: "" });
    setNotificationemailItemAddDlgOpen(true);
  };

  const openUpdateNotificationEmailItemDlg = (row:any) => {
    setNotificationEmailItemUpdateContent(row);
    setNotificationemailItemAddDlgOpen(true);
  };

  const deleteNotificationEmailItem = (row:any) => {
    setNotificationEmailItems(notificationEmailItems.filter(item => item !== row));
  };

  const handleCourtChange = (selected?: FormSelectOption) => {
    if (selected?.value == "ADD_NEW") {
      setIsCourtModalOpen(true);
    } else {
      setFieldValueCourt(selected?.value);
    }
  };

  const handleBarChange = (e: React.ChangeEvent<{ value: string }>) => {  
    if (e.target.value === "ADD_NEW") {  
      navigate("/user/bars/new?isFromOrder=true");  
    } else {  
      setFieldValueBarNumber(e.target.value);  
    }  
  };

  const handleOrderPhoneNumberChange = (e: React.ChangeEvent<{ value: string }>) => {
    const numberTxt = e.target.value.split("");
    if (numberTxt.length > 14) return;
    if (numberTxt.length == 1 && numberTxt[0] != "(") numberTxt.splice(0, 0, "(");
    if (numberTxt.length == 5 && numberTxt[4] != ")") numberTxt.splice(4, 0, ")", " ");
    if (numberTxt.length == 10 && numberTxt[9] != "-") numberTxt.splice(9, 0, "-");
    setFieldValuePhoneNumber(numberTxt.join(""));
  };

  // Open confirmation dialog when submit
  const onSubmit = (data: OrderInput) => {
    // Perform validation checks here
    const orderID = updateRecord?.ID;

    if (user) {
      console.log(user);
      if (user.state && user.email) {
        setSubmitData({
          ...data,
          ID: orderID,
          email: user.email,
          state: getStateCode(user.state),
          orderphoneNumber: data.orderphoneNumber, //clearFormat(data.orderphoneNumber),
          notify
        });

        handleConfirmDialogOpen();
      } else {
        toast.error("User is not an ordering party. Please complete your profile.");
      }
    } else {
      toast.error("Please login to the platform.");
    }
  };
  
  return (
    <main className="flex justify-center w-full">
      <form className="container flex flex-col pl-8 pr-24 py-16 fade-up-anim anim-500 gap-4" onSubmit={handleSubmit(onSubmit)}>
        <h1 className="text-3xl  mb-8">{updateRecord ? "UPDATE THE ORDER" : "CREATE A NEW ORDER"}</h1>
        {/* Hide order number read only field in create order page
        <LabelInputWrapper label="Order Number:" className="w-full md:w-2/5">
          <TextField
            variant="standard"
            className="w-full"
            value={fieldValueOrderNumber}
            disabled
          />
        </LabelInputWrapper> */}
        <SelectInput
          variant="outlined"
          className="w-full"
          label="Document Type"
          handler={register("type")}
          error={errors.type ? true : false}
          helperText={errors.type?.message}
          options={{
            data: orderType,
            labelKey: "label",
            valueKey: "value",
            //defaultValue: updateRecord?.type
            value: fieldValueType
          }}
          onChange={(e) => { setFieldValueType(e.target.value); }}
          required
        />
        <FormAutoComplete
          variant="outlined"
          className="w-full"
          label="Court of Docket"
          handler={register("court")}
          error={errors.court ? true : false}
          helperText={errors.court?.message}
          value={fieldValueCourt}
          options={courtSelectOptions}
          persistentItem={{ label: "Can not find your court? Select this to create the new one.", value: "ADD_NEW" }}
          onChange={handleCourtChange}
        />
        <TextField
          variant="outlined"
          className="w-full"
          label="Cause Number"
          {...register("causeNumber")}
          error={errors.causeNumber ? true : false}
          helperText={errors.causeNumber?.message}
          value={fieldValueCauseNumber}
          onChange={(e) => {setFieldValueCauseNumber(e.target.value);}}
          required
        />
        <TextField
          variant="outlined"
          className="w-full"
          label="Plaintiff Data"
          {...register("plaintiffs")}
          error={errors.plaintiffs ? true : false}
          helperText={errors.plaintiffs?.message}
          value={fieldValuePlaintiffList}
          onChange={(e) => {setFieldValuePlaintiffList(e.target.value);}}
          multiline
          rows={2}
          required
        />
        <TextField
          variant="outlined"
          className="w-full"
          label="Defendant Data"
          {...register("defendants")}
          error={errors.defendants ? true : false}
          helperText={errors.defendants?.message}
          value={fieldValueDefendantList}
          onChange={(e) => {setFieldValueDefendantList(e.target.value);}}
          multiline
          rows={2}
          required
        />
        <TextField
          variant="outlined"
          className="w-full"
          label="Cell Phone Number"
          placeholder="(###)###-####"
          {...register("orderphoneNumber")}
          error={errors.orderphoneNumber ? true : false}
          helperText={errors.orderphoneNumber?.message}
          value={fieldValuePhoneNumber}
          onChange={handleOrderPhoneNumberChange}
          required
        />
        <TextField
          variant="outlined"
          className="w-full"
          label="Customer Information"
          {...register("ordercustomerinfo")}
          error={errors.ordercustomerinfo ? true : false}
          helperText={errors.ordercustomerinfo?.message}
          value={fieldValueCustomerInformation}
          onChange={(e) => {setFieldValueCustomerInformation(e.target.value);}}
          required
        />
        <Grid container spacing={2}>
          <Grid item md={6}>
            <DateTimePicker 
              label="Order Start Date"
              className="w-full"
              value={fieldValueOrderStart}
              onChange={(newValue) => {
                setFieldValueOrderStart(newValue);
              }}
              slotProps={{
                textField: {
                  required: true,
                  helperText: errors.orderstartdate?.message || "",
                  error: !!errors.orderstartdate
                }
              }}
            />
          </Grid>
          <Grid item md={6}>
            <DateTimePicker 
              label="Order Stop Date"
              className="w-full"
              value={fieldValueOrderEnd}
              onChange={(newValue) => {
                setFieldValueOrderEnd(newValue);
              }}
              slotProps={{
                textField: {
                  required: true,
                  helperText: errors.orderstopdate?.message || "",
                  error: !!errors.orderstopdate
                }
              }}
            />
          </Grid>
        </Grid>
        <SelectInput
          variant="outlined"
          className="w-full"
          label="Carrier Code"
          handler={register("phoneCode")}
          error={errors.phoneCode ? true : false}
          helperText={errors.phoneCode?.message}
          options={{
            data: listOfCellphoneCarriers,
            labelKey: "companyname",
            valueKey: "ID",
            //defaultValue: updateRecord?.phoneCode
            value: fieldValuePhoneCode
          }}
          required
          onChange={(e) => {
            setFieldValuePhoneCode(e.target.value);
          }}
        />
        <SelectInput
          variant="outlined"
          className="w-full"
          label="Bar Number"
          handler={register("barNumber")}
          error={errors.barNumber ? true : false}
          helperText={errors.barNumber?.message}
          options={{
            data: listOfBarsForCurrentCourt,
            labelKey: "barState",
            labelAssistantKey: "barNumber",
            valueKey: "ID",
            //defaultValue: updateRecord?.barNumber
            value: fieldValueBarNumber,
            persistentItem: {
              value: "ADD_NEW",
              label: "Cannot find what you want? Add here."
            }
          }}
          required
          onChange={handleBarChange}
        />
        <FormControl className="w-full" >
          <label className="whitespace-nowrap mr-4">Notify to plaintiffs and defendants? *</label>
          <RadioGroup value={notify} onChange={({ target: { value } }) => { setNotify(value === "true"); }} row>
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
        <FormControl className="w-full" error={errors.notificationEmailItems ? true : false} >
          <label className="whitespace-nowrap mr-4">Notification Email List *</label>
          <OrderAccountLinkTable 
            isLoading={ isLoadingNotificationTable }
            notificationEmailList={notificationEmailItems} updateFunction={openUpdateNotificationEmailItemDlg} addFunction={openAddNotificationEmailItemDlg} deleteFunction={deleteNotificationEmailItem} 
          />
          <FormHelperText>{errors.notificationEmailItems?.message}</FormHelperText>
        </FormControl>
        <div className="flex gap-2 mt-12">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="bg-primary tracking-widest"
          >
            {"submit"}
          </Button>
          <Button
            variant="contained"
            color="error"
            className="bg-red-500 tracking-widest"
            onClick={() => setIsCancelConfirmationModalOpen(true)}
          >
            {"cancel"}
          </Button>
        </div>
      </form>
      <NotificationEmailItemAddDialog 
        open={notificationemailItemAddDlgOpen} 
        onClose={()=>handleNotificationemailItemAddDlgClose()} 
        data={notificationEmailItemUpdateContent} 
        saveFunction={(data)=>{
          const existItemIndex = notificationEmailItems.findIndex(item => item.email === data.email && item.partyRepresented === data.partyRepresented && item.orderingParty === data.orderingParty && item.barID === data.barID );
          if(existItemIndex > -1)
            alert("No duplicated record, please!");
          else
            setNotificationEmailItems([...notificationEmailItems, data]);
        }} 
        updateFunction={(data)=>{
          const tmpEmailItems = notificationEmailItems.map(item => {
            if(item.email === notificationEmailItemUpdateContent.email && 
              item.partyRepresented === notificationEmailItemUpdateContent.partyRepresented &&
              item.orderingParty === notificationEmailItemUpdateContent.orderingParty &&
              item.barID === notificationEmailItemUpdateContent.barID
            ) {
              return data;
            } else {
              return item;
            }
          });
          setNotificationEmailItems(tmpEmailItems);
        }} 
      />
      <ConfirmDlg
        open={isConfirmDialogOpen} 
        onClose={handleConfirmDialogClose} 
        onApprove={clickApprove} 
        onCancel={clickCancel} 
        isLoading={isLoading} 
        submitData={submitData} 
      />
      <AddCourtModal 
        open={isCourtModalOpen}
        onClose={() => setIsCourtModalOpen(false)}
        setCourtField={setFieldValueCourt}
      />
      <CancelConfirmationModal 
        open={isCancelConfirmationModalOpen}
        title="If you cancel this, all the information you entered will be removed. Are you sure to do this?"
        onApprove={clickCancel}
        onClose={() => setIsCancelConfirmationModalOpen(false)}
      />
    </main>
  );
}
