import React, {useEffect}  from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  TableSortLabel,
  Paper,
  LinearProgress,
  Button
} from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";

import {
  PlayCircleFilledWhite as PlayCircleFilledWhiteIcon,
  PauseCircleFilled as PauseCircleFilledIcon,
  LockOpen as LockOpenIcon,
  Cancel as CancelIcon,
  Edit as EditIcon,
  FileDownload as FileDownloadIcon
} from "@mui/icons-material";

import { 
  orderTableHeadCells, orderType 
} from "../../config/static-data";
import { Order, OrderListType } from "../../types/order";
//import { formatPhoneNumber } from "../../utils/functions";
import { EnhancedOrderTableProps } from "../../types/props";
import { getOrderList, updateOrderStatus, getOrderDraft } from "../../redux/actions/order.action";
import { getCourtList } from "../../redux/actions/court.action";
import { getCellphonecarrierList } from "../../redux/actions/cellphonecarrier.action";
import { getFieldValueFromList } from "../../utils/functions";
import OrderDetailDialog from "./OrderDetailDialog";
import { ReduxResponse } from "../../types/store";
import { toast } from "react-toastify";
import { downloadOrderResult } from "./OrderDownloadResult";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableHead = (props: EnhancedOrderTableProps) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof OrderListType) => (event: React.MouseEvent<unknown>) => onRequestSort(event, property);

  return (
    <TableHead>
      <TableRow>
        <TableCell
          key="index"
          align="center"
          className="whitespace-nowrap"
        >
          No
        </TableCell>
        {orderTableHeadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            className="whitespace-nowrap"
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
          key="index"
          align="center"
          className="whitespace-nowrap"
        >
          Process Status
        </TableCell>
        <TableCell
          key="index"
          align="center"
          className="whitespace-nowrap"
          sx={{
            position: "sticky",
            right: 0,
            zIndex: 1,
            backgroundColor: "#1E1E1E"
          }}
        >
          Action
        </TableCell>
        <TableCell
          key="index"
          align="center"
          className="whitespace-nowrap"
        >
          Update
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const OrderListTable = ({ search, updateFunction }: { search: string , updateFunction: (record: any) => void} ) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, total, list, isUpdatingStatus, draft } = useSelector(({ orderReducer }) => orderReducer);
  const { list:courtList } = useSelector(({courtReducer}) => courtReducer);
  const { list:cellphonecarrierList } = useSelector(({cellphoneCarrierReducer}) => cellphoneCarrierReducer);

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof OrderListType>("ID");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState<OrderListType>();

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof OrderListType) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: number = parseInt(event.target.value, 10);
    setRowsPerPage(newValue);
    setPage(0);
  };

  const visibleRows: any = React.useMemo(() =>
    stableSort(list, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    ), [order, orderBy, page, rowsPerPage, list],
  );

  
  const handleDialogOpen = (record: OrderListType) => {
    //setSelectedOrder(record);
    //setDialogOpen(true);
    console.log(setSelectedOrder, setDialogOpen, record);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const updateOrderStatusPlay = (id:string) => {
    dispatch(updateOrderStatus({id, status:"waiting"}))
      .then((response: ReduxResponse) => {
        if (response.success) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error: any) => toast.error(error));
  };

  const updateOrderStatusPause = (id:string) => {
    dispatch(updateOrderStatus({id, status:"paused"}))
      .then((response: ReduxResponse) => {
        if (response.success) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error: any) => toast.error(error));
  };

  const updateOrderStatusWaive = (id:string) => {
    dispatch(updateOrderStatus({id, status:"waived"}))
      .then((response: ReduxResponse) => {
        if (response.success) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error: any) => toast.error(error));
  }; 

  const updateOrderStatusCancel = (id:string) => {
    dispatch(updateOrderStatus({id, status:"canceled"}))
      .then((response: ReduxResponse) => {
        if (response.success) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error: any) => toast.error(error));
  }; 

  React.useEffect(() => {
    dispatch(getOrderList());
    dispatch(getOrderDraft());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCellphonecarrierList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCourtList());
  }, [dispatch]);

  let currentNumber = page * rowsPerPage;

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between items-center w-full p-4">
        <h1 className="text-xl">Recent Orders</h1>
        {list.length > 0 && isLoading && <CachedIcon className="animate-spin" />}
      </div>

      {
        isLoading ? (
          <div className="flex justify-center w-full px-12 py-32">
            <LinearProgress className="w-96" />
          </div>
        ) : list.length > 0 ? (
          <Box className="w-full fade-up-anim anim-500">
            <Paper className="w-full mb-1">
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="orderListTable"
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={list.length}
                  />
  
                  <TableBody>
                    {
                      draft ? 
                        <TableRow
                          className="cursor-pointer"
                          hover
                        >
                          <TableCell className="whitespace-nowrap">*</TableCell>
                          <TableCell className="whitespace-nowrap" align="right">DRAFT</TableCell>
                          <TableCell className="whitespace-nowrap" align="right" >{draft.type}
                            {orderType.map(type => type.value === draft[0].type && type.label)}
                          </TableCell>
                          <TableCell className="whitespace-nowrap" align="right">{getFieldValueFromList(courtList, "code", draft[0].court)}</TableCell>
                          <TableCell className="whitespace-nowrap" align="right" >{draft[0].causeNumber}</TableCell>
                          <TableCell className="whitespace-nowrap" align="right" style={{ maxWidth: "300px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} >
                            {draft[0].plaintiffs}
                          </TableCell>
                          <TableCell className="whitespace-nowrap" align="right" style={{ maxWidth: "300px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} >{draft[0].defendants}</TableCell>
                          <TableCell className="whitespace-nowrap" align="right" >{/*formatPhoneNumber(draft[0].phoneNumber)*/draft[0].orderphoneNumber}</TableCell>
                          <TableCell className="whitespace-nowrap" align="right" >{getFieldValueFromList(cellphonecarrierList, "companyname", draft[0].phoneCode)}</TableCell>
                          <TableCell className="whitespace-nowrap" align="right" ></TableCell>
                          <TableCell className="whitespace-nowrap" align="right" ></TableCell>
                          <TableCell className="whitespace-nowrap" align="right" ></TableCell>
                          <TableCell className="whitespace-nowrap" align="right" ></TableCell>
                          <TableCell 
                            className="whitespace-nowrap" 
                            align="right" 
                            sx={{
                              position: "sticky",
                              zIndex: 2,
                              right: 0,
                              backgroundColor: "#1E1E1E",
                              minWidth: "120px"
                            }}
                          ></TableCell>
                          <TableCell className="whitespace-nowrap" align="right">
                            <Button
                              variant="contained"
                              startIcon={<EditIcon />}
                              className="bg-primary"
                              size="small"
                              sx={{
                                textTransform: "none"
                              }}
                              onClick={() => navigate("/order/save")}
                              disabled={isUpdatingStatus}
                            >
                              <p className="ml-2">Update</p>
                            </Button>
                          </TableCell>
                        </TableRow>
                        :
                        <></>
                    }
                    
                    {
                      visibleRows.map((row: OrderListType) => JSON.stringify(row).toLowerCase().includes(search.toLowerCase()) && (
                        <TableRow
                          key={row.ID}
                          role="checkbox"
                          tabIndex={-1}
                          className="cursor-pointer"
                          //onClick={() => handleDialogOpen(row)}
                          //onClick={() => updateFunction(row)}
                          hover
                        >
                          <TableCell className="whitespace-nowrap" onClick={() => handleDialogOpen(row)}>{++currentNumber}</TableCell>
                          <TableCell className="whitespace-nowrap" align="right" onClick={() => handleDialogOpen(row)}>{row.orderNumber}</TableCell>
                          <TableCell className="whitespace-nowrap" align="right" onClick={() => handleDialogOpen(row)} >
                            {orderType.map(type => type.value === row.type && type.label)}
                          </TableCell>
                          <TableCell className="whitespace-nowrap" align="right" onClick={() => handleDialogOpen(row)}>{getFieldValueFromList(courtList, "code", row.court)}</TableCell>
                          <TableCell className="whitespace-nowrap" align="right" onClick={() => handleDialogOpen(row)}>{row.causeNumber}</TableCell>
                          <TableCell className="whitespace-nowrap" align="right" style={{ maxWidth: "300px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} onClick={() => handleDialogOpen(row)}>
                            {row.plaintiffs}
                          </TableCell>
                          <TableCell className="whitespace-nowrap" align="right" style={{ maxWidth: "300px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} onClick={() => handleDialogOpen(row)}>{row.defendants}</TableCell>
                          <TableCell className="whitespace-nowrap" align="right" onClick={() => handleDialogOpen(row)}>{/*formatPhoneNumber(row.phoneNumber)*/row.orderphoneNumber}</TableCell>
                          <TableCell className="whitespace-nowrap" align="right" onClick={() => handleDialogOpen(row)}>{getFieldValueFromList(cellphonecarrierList, "companyname", row.phoneCode)}</TableCell>
                          <TableCell className="whitespace-nowrap" align="right" onClick={() => handleDialogOpen(row)}>{row.updatedAt}</TableCell>
                          <TableCell className="whitespace-nowrap" align="right" onClick={() => handleDialogOpen(row)}>{row.opStartAt}</TableCell>
                          <TableCell className="whitespace-nowrap" align="right" >{row.opEndAt}</TableCell>
                          <TableCell className="whitespace-nowrap" align="right" >{row.orderStatus ? row.orderStatus : ""}</TableCell>
                          <TableCell
                            className="whitespace-nowrap"
                            align="center"
                            sx={{
                              position: "sticky",
                              zIndex: 2,
                              right: 0,
                              backgroundColor: "#1E1E1E",
                              minWidth: "120px"
                            }}
                          >
                            {
                              row.orderStatus === "waiting" ? (
                                <>
                                  <Button
                                    variant="contained"
                                    startIcon={<PauseCircleFilledIcon />}
                                    className="bg-primary"
                                    size="small"
                                    sx={{
                                      textTransform: "none"
                                    }}
                                    onClick={() => updateOrderStatusPause(row.ID)}
                                    disabled={isUpdatingStatus}
                                  >
                                    <p className="ml-2">Pause</p>
                                  </Button>
                                  <Button
                                    variant="contained"
                                    startIcon={<LockOpenIcon />}
                                    className="bg-primary"
                                    size="small"
                                    sx={{
                                      textTransform: "none"
                                    }}
                                    onClick={() => updateOrderStatusWaive(row.ID)}
                                    disabled={isUpdatingStatus}
                                  >
                                    <p className="ml-2">Waive</p>
                                  </Button>
                                  <Button
                                    variant="contained"
                                    startIcon={<CancelIcon />}
                                    className="bg-secondary"
                                    size="small"
                                    sx={{
                                      textTransform: "none"
                                    }}
                                    disabled={isUpdatingStatus}
                                  >
                                    <p className="ml-2">Cancel</p>
                                  </Button>
                                </>
                              ) : row.orderStatus === "waived" ? (
                                <>
                                  {
                                    row.orderdocumentbate ? 
                                      <Button
                                        variant="contained"
                                        startIcon={<FileDownloadIcon />}
                                        className="bg-primary"
                                        size="small"
                                        sx={{
                                          textTransform: "none"
                                        }}
                                        onClick={() => downloadOrderResult(row)}
                                      >
                                        <p className="ml-2">Download Result</p>
                                      </Button>
                                      : 
                                      "preparing document..."
                                  }
                                </>
                              ) :  row.orderStatus === "expired" || row.orderStatus === "canceled" ? (
                                <></>
                              ) :(
                                <>
                                  <Button
                                    variant="contained"
                                    startIcon={<PlayCircleFilledWhiteIcon />}
                                    className="bg-primary"
                                    size="small"
                                    sx={{
                                      textTransform: "none"
                                    }}
                                    onClick={() => updateOrderStatusPlay(row.ID)} 
                                    disabled={isUpdatingStatus}
                                  >
                                    <p className="ml-2">Start</p>
                                  </Button>
                                  <Button 
                                    variant="contained" 
                                    startIcon={<CancelIcon />} 
                                    className="bg-secondary" 
                                    size="small"
                                    sx={{
                                      textTransform: "none"
                                    }}
                                    disabled={isUpdatingStatus} 
                                    onClick={() => updateOrderStatusCancel(row.ID)}
                                  >
                                    <p className="ml-2">Cancel</p>
                                  </Button>
                                </>
                              )
                            }
                          </TableCell>
                          <TableCell className="whitespace-nowrap" align="right">{
                            row.orderStatus === "waiting" || row.orderStatus === "waived" || row.orderStatus === "expired" || row.orderStatus === "canceled" ? (
                              <></>
                            ) : (
                              <Button 
                                variant="contained"
                                startIcon={<EditIcon />}
                                className="bg-primary"
                                size="small"
                                sx={{
                                  textTransform: "none"
                                }}
                                onClick={() => updateFunction(row)} disabled={isUpdatingStatus}
                              >
                                <p className="ml-2">Update</p>
                              </Button>
                            )
                          }
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        ) : (
          <div className="flex flex-col items-center w-full">
            <img
              alt="placeholder"
              src="/assets/images/order/list-empty.png"
              width={200}
            />

            <h1 className="mt-8 text-lg text-center">
              The current order list is empty.<br />Please initiate the creation of a new order by clicking the designated button.
            </h1>
          </div>
        )
      }

      <OrderDetailDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        data={selectedOrder}
      />
    </div>
  );
};

export default OrderListTable;