import { useMemo } from "react";
import { FormSelectOptions } from "../types/common";

type GetSelectOptions = (
  data: any[] | undefined,
  labelAttr: string,
  valueAttr: string,
  additionalLabelAttr?: string,
  joinSymbol?: string,
) => FormSelectOptions;

export const getSelectOptionsFromArrayData: GetSelectOptions = (
  data = [],
  labelAttr,
  valueAttr,
  additionalLabelAttr,
  joinSymbol = " - ",
) => {
  const rlt = data.map((item) => {
    const value = item[valueAttr];
    let label = item[labelAttr];

    if (additionalLabelAttr) {
      label += `${joinSymbol}${item[additionalLabelAttr]}`;
    }

    return { label, value };
  });

  return rlt;
};

export const useSelectOptionsMemo: GetSelectOptions = (
  data = [],
  labelAttr,
  valueAttr,
  additionalLabelAttr,
  joinSymbol,
) =>
  useMemo(
    () =>
      getSelectOptionsFromArrayData(data, labelAttr, valueAttr, additionalLabelAttr, joinSymbol),
    [data],
  );
