import React from "react";
import { Button, Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";

interface Props {
  title: string;
  open: boolean;
  onClose: () => void;
  onApprove: () => void;
}
const CancelConfirmationModal:React.FC<Props> = ({
  title,
  open, 
  onClose,
  onApprove
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        <Stack gap={2}>
          <Stack sx={{ display: "flex", flexDirection: "row", alignSelf: "end" }} gap={2}>          
            <Button
              size="small"
              variant="contained"
              color="primary"
              className="bg-primary tracking-widest"
              sx={{
                textTransform: "none"
              }}
              onClick={onApprove}
            >
              Proceed
            </Button>
            <Button
              size="small"
              variant="contained"
              color="error"
              className="bg-red-500 tracking-widest"
              sx={{
                textTransform: "none"
              }}
              onClick={onClose}
            >
              Close
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default CancelConfirmationModal;