import React from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";

import { numberTo2Digit } from "../../utils/functions";
import { SelectInputProps } from "../../types/props";

const SelectInput = (props: SelectInputProps) => {
  //  Destructure component props
  const { label, variant, className, handler, error, helperText, options, required, onChange } = props;
  const { data, valueKey, labelKey, labelAssistantKey, value, persistentItem }: any = options;

  return (
    <FormControl className={className} error={error}>
      <InputLabel id="item-select">{label}</InputLabel>
      <Select
        labelId="item-select"
        label={label}
        variant={variant ? variant : "outlined"}
        value={value ? value : ""}
        {...handler}
        MenuProps={{
          PaperProps: {
            style: { maxHeight: 240 },
          },
        }}
        required={required ? required : false}
        onChange={onChange ? onChange : null}
      >
        <MenuItem hidden value="">{label}</MenuItem>
        {data.map((option: string) => (
          <MenuItem key={option[valueKey]} value={valueKey ? option[valueKey] : option} >
            {labelKey ? labelAssistantKey ? (option[labelKey] + ":" + option[labelAssistantKey]) : option[labelKey] : numberTo2Digit(Number(option))}
          </MenuItem>
        ))}
        {
          persistentItem &&
            <MenuItem key={persistentItem.value} value={persistentItem.value}>{persistentItem.label}</MenuItem>
        }
      </Select>

      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default SelectInput;
