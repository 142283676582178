import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { DarkThemeProvider } from "./providers/MuiThemeProvider";
import StoreProvider from "./providers/StoreProvider";
import AuthorizingProvider from "./providers/AuthorizingProvider";

import AppRoutes from "./providers/AppRoutes";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function App() {
  
  return (
    <StoreProvider>
      <DarkThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Router>
            <AuthorizingProvider>
              <AppRoutes />
            </AuthorizingProvider>
          </Router>
        </LocalizationProvider>
      </DarkThemeProvider>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </StoreProvider>
  );
}
