export interface AddCourtFormInputs {
  code: string | null;
  label: string | null;
  waitPeriod: string | null;
  responsePeriod?: string | null;
}

export const defaultAddCourtFormValues: AddCourtFormInputs = {
  code: null,
  label: null,
  waitPeriod: "2",
  responsePeriod: "2"
};

export interface AddCourtReturnType extends AddCourtFormInputs {}