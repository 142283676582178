import React from "react";  
import { Autocomplete, FormControl, FormHelperText, TextField } from "@mui/material";  
import { FormSelectOption, FormSelectOptions } from "../../types/common";  

interface Props {  
  label?: string;  
  variant?: string;  
  className?: string;  
  handler: any;  
  error: boolean;  
  helperText: string | undefined;  
  value: string;  
  options: FormSelectOptions;  
  persistentItem?: FormSelectOption; // Add optional prop for persistent item  
  onChange?: (value: any) => void;  
}  

const FormAutoComplete: React.FC<Props> = ({  
  label,  
  className,  
  handler,  
  error,  
  helperText,  
  value,  
  options,  
  onChange,  
  persistentItem, // Destructure persistent item from props  
}) => {  
  // Create a combined array of options including the persistent item at the end if provided  
  const combinedOptions = persistentItem ? [...options, persistentItem] : options;  

  // Custom filter function  
  const filterOptions = (options: FormSelectOptions, { inputValue }: { inputValue: string }) => {  
    // Filter options based on user input  
    const filtered = options.filter(option =>  
      option.label?.toLowerCase().includes(inputValue.toLowerCase())  
    );  
    // Append the persistent item only if it’s not part of the filtered results and user input is present  
    if (persistentItem && inputValue && !filtered.some(option => option.value === persistentItem.value)) {  
      return [...filtered, persistentItem];  
    }  
    return filtered;  
  };  

  return (  
    <FormControl className={className} error={error}>  
      <Autocomplete  
        {...handler}  
        options={combinedOptions}  
        getOptionLabel={(option: FormSelectOption) => option.label ?? ""}  
        // Set value based on the passed value or fallback to the persistent item  
        value={combinedOptions.find(option => option.value === value) || null}   
        onChange={onChange ? (event, newValue) => onChange(newValue) : undefined}  
        // Use custom filter function  
        filterOptions={filterOptions}  
        renderInput={(params) => <TextField {...params} label={label} />}  
        MenuProps={{  
          PaperProps: {  
            style: { maxHeight: 240 },  
          },  
        }}  
      />  
      <FormHelperText>{helperText}</FormHelperText>  
    </FormControl>  
  );  
};  

export default FormAutoComplete;