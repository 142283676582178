import React, { useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { AddCourtFormInputs, defaultAddCourtFormValues } from "../../types/court";
import { FormProvider, useForm } from "react-hook-form";
import SelectInput from "../custom-input/SelectInput";
import { stateInfo } from "../../config/static-data";
import { useDispatch } from "react-redux";
import { addCourt } from "../../redux/actions/court.action";
import { ReduxResponse } from "../../types/store";
import { toast } from "react-toastify";

interface Props {
  open: boolean;
  onClose: () => void;
  setCourtField: (val: string) => void;
}
const AddCourtModal:React.FC<Props> = ({
  open, 
  onClose,
  setCourtField
}) => {
  const [selectedState, setSelectedState] = useState("");

  const dispatch = useDispatch();

  const formContext = useForm<AddCourtFormInputs>({
    criteriaMode: "all",
    defaultValues: defaultAddCourtFormValues
  });

  const { formState, register, reset } = formContext;
  const { errors } = formState;

  const onSubmit = async (data: AddCourtFormInputs) => {
    dispatch(addCourt(data))
      .then((response: ReduxResponse) => {
        if (response.success) {
          toast.success(response.message);
          if (response.data?.ID) {
            setCourtField(response.data?.ID || "");
          }
          reset();
          setSelectedState("");
          onClose();
        } else {
          toast.error(response.message);
        }
      })
      .catch((error: any) => toast.error(error));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New Court</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        <FormProvider {...formContext}>
          <Stack gap={2}>
            <SelectInput
              label="State"
              variant="outlined"
              handler={register("code")}
              error={errors.code ? true : false}
              helperText={errors.code?.message}
              options={{
                data: stateInfo,
                value: selectedState,
                labelKey: "abbreviation",
                valueKey: "abbreviation",
              }}
              onChange={(e) => setSelectedState(e.target.value)}
              required={true}
            />
            <TextField
              variant="outlined"
              label="Court Label"
              {...register("label")}
              error={errors.label ? true : false}
              helperText={errors.label?.message}
              required
            />
            <TextField
              variant="outlined"
              label="Wait Period (days)"
              {...register("waitPeriod")}
              error={errors.waitPeriod ? true : false}
              helperText={errors.waitPeriod?.message}
              required
            />
            <TextField
              variant="outlined"
              label="Response Period (days)"
              {...register("responsePeriod")}
              error={errors.responsePeriod ? true : false}
              helperText={errors.responsePeriod?.message}
            />
            <Stack sx={{ display: "flex", flexDirection: "row", alignSelf: "end" }} gap={2}>          
              <Button
                variant="contained"
                color="primary"
                className="bg-primary tracking-widest"
                onClick={formContext.handleSubmit(onSubmit)}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="error"
                className="bg-red-500 tracking-widest"
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default AddCourtModal;