import { Dispatch } from "redux";
import * as Actions from "../constants";
import axios, { AxiosError } from "axios";
import { handleAxiosError } from "../../utils/functions";

// Get court list action
export const getCourtList: any = () => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.GET_COURT_LIST_REQUEST });
  return axios.get(`${process.env.REACT_APP_SERVER_API}/court`)
    .then(response => {
      if(response.data.success === true) {
        dispatch({
          type: Actions.GET_COURT_LIST_SUCCESS,
          payload: response.data
        });
        return response.data;
      } else {
        dispatch({
          type: Actions.GET_COURT_LIST_FAILURE
        });
      }
    })
    .catch((error: AxiosError) => {
      console.log(error);
      dispatch({
        type: Actions.GET_COURT_LIST_FAILURE,
        error
      });
      return [];
      //handleAxiosError(error);
    });
};

// Add a court
export const addCourt: any = (courtData:any) => async(dispatch: Dispatch) => {
  dispatch({ type: Actions.ADD_COURT_REQUEST });
  return axios.post(`${process.env.REACT_APP_SERVER_API}/court/`, courtData)
    .then(response => {
      if(response.data.success) {
        dispatch({
          type: Actions.ADD_COURT_SUCCESS,
          payload: response.data
        });
        return response.data;
      } else {
        dispatch({
          type: Actions.ADD_COURT_FAILURE,
          payload: response.data
        });
        return response.data;
      }
    })
    .catch((error: AxiosError) => { 
      dispatch({
        type: Actions.ADD_COURT_FAILURE,
        error
      });
      handleAxiosError(error);
    });
};
